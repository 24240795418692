import c from "../plan-template/Sidebar.module.scss"
import {Fragment, useContext, useRef} from "react";
import { SidebarContextProvider } from "../../context/sidebar.context";
import ChapterItem from "./ChapterItem";
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import CollapseContainerContext from "../../context/collapse-container.context";
import {usePlanCompileStore} from "../../store/plan-compile.store";
import {searchInOrganizationPlan} from "../../services/organizations.service";
import debounce from "lodash.debounce";
import EmptyBox from "../emptystate/EmptyBox";


function Sidebar({ isAdmin }) {

    const [
        chapterList,
        searchModel,
        searchLoading,
        searchResults,
        planDetail,
        updateState,
    ] = usePlanCompileStore(state => [
        state.chapterList,
        state.searchModel,
        state.searchLoading,
        state.searchResults,
        state.planDetail,
        state.updateState
    ])

    const { forceOpen, forceClose } = useContext(CollapseContainerContext)


    const abortController = useRef(new AbortController());


    function searchChapter(e){

        const search = e.target.value

        if(search.trim().length > 2){
            /*forceClose()*/
            /*setTimeout(( ) => updateState({ searchModel: search}), 0)*/

            updateState({ searchLoading: true})

            abortController.current.abort()
            abortController.current = new AbortController()

            const body = {
                id: planDetail.organization_id,
                idPlan: planDetail.id,
                q: search.trim().toLowerCase()
            }

            searchInOrganizationPlan({ params: body, options: { signal: abortController.current.signal } },
                (res) => {
                    updateState({
                        searchLoading: false,
                        searchModel: search.trim().toLowerCase(),
                        searchResults: res
                    })
                }, (err) => {
                    updateState({ searchLoading: false})
                })

        }else{
            updateState({ searchModel: ''})
        }
    }

    const debounceSearch = debounce(searchChapter, 500)


    const filteredChapters = chapterList.filter(chapter => (searchResults.organization_chapters.includes(chapter.content_id) || !searchModel))

    return (
        <SidebarContextProvider>
            <div className={c.main_sidebar}>
                <div className={c.wrap_sidebar}>

                    {/* Header Sidebar */}
                    <div className={c.main_header_sidebar}>
                        <div className={c.main_buttons}>

                            {/* Collapse Buttons */}
                            <div className={c.main_button} onClick={forceOpen} data-tooltip-id="tooltip_small_top" data-tooltip-offset={10} data-tooltip-position-strategy="fixed"  data-tooltip-content="Espandi tutti">
                                <span className="icon_16 default expand icon_mask !bg-interactive-100"></span>
                            </div>
                            <div className={c.main_button} onClick={forceClose} data-tooltip-id="tooltip_small_top" data-tooltip-offset={10} data-tooltip-position-strategy="fixed"  data-tooltip-content="Riduci tutti">
                                <span className="icon_16 default collapsed icon_mask !bg-interactive-100"></span>
                            </div>
                        </div>

                        {/* Search */}
                        <div className={c.main_search}>
                            <div className="wrap_input_landing icon">
                                <input onChange={debounceSearch} type="text" className="landing" placeholder="Ricerca..." />
                                <span className="icon_16 default search"></span>
                            </div>
                        </div>
                    </div>


                    {/* Content Sidebar */}
                    <div className={c.main_content_sidebar}>
                        
                        {filteredChapters.length > 0 ?
                            <div className={c.main_list_sidebar}>
                                <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className={c.wrap_list_sidebar}>
                                    
                                    {/* Drag Item */}
                                    {filteredChapters.map((chapter, index) => (
                                        <Fragment key={chapter.content_id}>
                                            <ChapterItem
                                                isEditable={isAdmin}
                                                position={index}
                                                chapter={chapter}
                                                /*disabled={!isSearched(chapter)}*/
                                            />
                                        </Fragment>
                                    ))}
                                </OverlayScrollbarsComponent>
                            </div>
                            :
                            <div className="py-6">
                                <EmptyBox
                                    title={"Nessun risultato trovato"}
                                    icon={"icon_75 default archive_plan"}
                                />
                            </div>
                        }

                        {searchLoading &&
                            <div className={c.main_loading}>
                                <div className="main_comp_loader_box_icon !bg-dark-70"></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </SidebarContextProvider>
    );
}

export default Sidebar;