import { forwardRef } from "react";
import "../plan-template/CardChapterContent.scss"
import IconContainer from '../utils/IconContainer';


const CardChapterContent = forwardRef(({className = '', item, counterReqs, isDragging, position, onClick, isActived, isContents, dragHandleProps, isEditable, onEdit = () => {}, onDelete = () => {}, toComplete, ...props}, ref) => {

    return (
        <div ref={ref} {...props} onClick={() => onClick(item, isContents)} className={"main_comp_card_chapter_content " + (isDragging ? " is_dragging " : '') + (isActived ? " active " : '') + (!isEditable ? " not_draggable " : '' ) + (className)}>
            <div className="main_intro">
                <div className="wrap_intro">

                    <div className="main_icon type">
                        <span className={"icon_16 default " + (isContents ? "text_box" : "layers")}></span>
                    </div>



                    {!isContents &&
                        item.status != "default" &&
                            <div className="main_icon">
                                <span className={"icon_16 default icon_mask !bg-secondary-100 " + (item.status === "new" ? "megaphone" : "update")}></span>
                            </div>
                    }

                    {isContents &&
                        item.editable == false &&
                            <div className="main_icon">
                                <span className={"icon_16 default icon_mask !bg-secondary-100 lock"}></span>
                            </div>
                    }

                    <span className="title">{isContents ? "Documento"  : item.name}</span>

                </div>
                {toComplete &&
                    <IconContainer icon="danger_corp" tooltip="Da gestire" />
                } 
            </div>

            <div className="main_desc">

                <div className="desc">{isContents ? item.name : item.description}</div>

                {!isContents &&
                    <div className="main_sum">
                        <div className="main_icon">
                            <span className="icon_16 layer default"></span>
                        </div>
                        {counterReqs !== undefined && <span><b>{counterReqs}</b> linee d'azione</span>}
                    </div>
                }
            </div>
        </div>
);
})

export default CardChapterContent;