import c from "./TableContainer.module.scss"
import Pagination from "../utils/Pagination";
import FilterDropdown from '../utils/FilterDropdown';
import Table from "../utils/table/Table";
import {Link} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {getOrganizationPlan, getOrganizations} from "../../services/organizations.service";
import debounce from "lodash.debounce";
import useLoadingHelper from "../../hooks/loading.hook";
import LoadingBox from "../loading/LoadingBox";
import EmptyBox from "../emptystate/EmptyBox";
import useDateFormatter from '../../hooks/date-formatter.hook';
import {getResellers} from "../../services/resellers.service";
import {getWorkingTemplatePlans} from "../../services/plans-template-status.service";
import FilterDropdownCollapse from "../utils/FilterDropdownCollapse";
import PlanDownloadModal from "../popups/PlanDownloadPopup";
import {formatOrganizationPlanDownloadObject} from "../../helper/formatter.helper";
import CrudApprovalPopup from "../popups/plan-compile/CrudApprovalPopup";
import LoaderPage from "../utils/LoaderPage";


function TableEntities({isReseller}) {

    const [organizations, setOrganizations] = useState([])

    const [resellersLoading, setResellersLoading] = useState(false)

    const [resellers, setResellers] = useState([])

    const [planStatus, setPlanStatus] = useState([])

    const [planData, setPlanData] = useState(null)

    const [loadingStatus, setLoadingStatus] = useState(false)

    const [openPrinterModal, setOpenPrinterModal] = useState(false);

    const [openApprovalModal, setOpenApprovalModal] = useState(false);

    const [loadingPage, setLoadingPage] = useState(false)

    const {queueLoading, addQueue, removeQueue, initStatus, setInitStatus} = useLoadingHelper()

    const {formatDate} = useDateFormatter()

    const [queryParams, setQueryParams] = useState({
        page: 1,
        filter: {
            name: "",
            "workingOrganizationPlans.organizationRequirements.statuses": [],
            "workingOrganizationPlans.approved": null,
            reseller_id: [],
            "workingOrganizationPlans.template_plan_id": null
        },
        sort: '-last_activity_at,name'
    })

    const [pageData, setPageData] = useState({
        last: 0,
        current: 0,
        total: 0
    })

    const subscriptionStatus = {
        'inactive': {'label': 'Scaduto', 'classText': 'text-red-100', 'classCircle': 'bg-red-100'},
        'warning': {'label': 'In scadenza', 'classText': 'text-danger-100', 'classCircle': 'bg-danger-100'},
        'danger': {'label': 'In scadenza', 'classText': 'text-danger-100', 'classCircle': 'bg-danger-100'},
        'active': {'label': 'Attivo', 'classText': 'text-success-100', 'classCircle': 'bg-success-100'}
    }


    /**
     * Entity access
     * @param el
     * @returns {`/admin/entities/entity/${*}/plan/${string}/compile`|`/reseller/${*}/plan/${string}/compile`}
     */
    const entityAccessLink = (plan) => {
        if (!isReseller) {
            return `/admin/entities/entity/${plan.organization_id}/plan/${plan.id}/compile`
        } else {
            return `/reseller/${plan.organization_id}/plan/${plan.id}/compile`
        }
    }


    /**
     * Fetch data
     */
    useEffect(() => {

        getListOrganizations()

    }, [queryParams])

    /**
     * Fetch data
     */
    useEffect(() => {

        if (!isReseller)
            getListResellers()

    }, [])


    /**
     * Get List organizations
     */
    const getListOrganizations = () => {

        const body = {
            include: ["organizationType", "reseller", "workingOrganizationPlans", "lastActivity"],
            ...queryParams
        }

        addQueue()

        // Fetch Api
        getOrganizations(body, (res) => {

            setOrganizations(res.data)

            removeQueue()

            setInitStatus(true)

            setPageData({
                last: res.meta.last_page,
                current: res.meta.current_page,
                total: res.meta.total
            })
        }, () => {
            removeQueue()
        })
    }

    /**
     * Get List organizations
     */
    const getListResellers = async () => {

        // Fetch Api
        setResellersLoading(true)

        const getAllResellers = async (page = 1, loadMore = false) => {

            // Fetch Api
            await getResellers({page}, async (res) => {

                loadMore ? setResellers(resellers => [...resellers, ...res.data]) : setResellers(res.data);

                if (res.meta.current_page < res.meta.last_page) {
                    await getAllResellers(res.meta.current_page + 1, true)
                } else {
                    setResellersLoading(false)
                }
            }, () => {

                setResellersLoading(false)
            })
        }

        await getAllResellers();
    }


    /**
     * Update page
     * @param {*} newPage
     * @returns
     */
    const updatePage = (newPage) => setQueryParams(q => ({...q, page: newPage}))


    /**
     * Debounce Search
     */
    const debouncedSearch = debounce(onSearch, 300)


    /**
     * Search event function
     */
    function onSearch(e) {

        const searchModel = e.target.value

        if (searchModel.trim().length > 0 || queryParams.filter.name != null) {
            setQueryParams(q => ({
                ...q,
                page: 1,
                filter: {...q.filter, name: searchModel.trim().length > 0 ? searchModel : ''}
            }))
        }
    }


    /**
     * Filter by status
     * @param {*} items
     */
    const filterByStatus = (items) => {
        const filter = planStatus.reduce((acc, plan) => {
            const statusList = plan.requirement_statuses.filter(s => items.includes(s.id)).map(s => s.id)
            return !!statusList.length ? [...acc, { template_plan_id: plan.id, requirement_status_ids: statusList }] : acc
        }, [])

        setQueryParams(q => ({
            ...q,
            page: 1,
            filter: {
                ...q.filter, "workingOrganizationPlans.organizationRequirements.statuses": filter
            }
        }))
    }


    /**
     * Filter by plan
     * @param {*} items
     */
    const changeFilters = (key, item) => setQueryParams(q => ({
        ...q,
        page: 1,
        filter: {...q.filter, [key] : item}
    }))


    const filteredStatus = !!queryParams.filter["workingOrganizationPlans.organizationRequirements.statuses"]?.length ?
        queryParams.filter["workingOrganizationPlans.organizationRequirements.statuses"].map(list => list.requirement_status_ids).flat(Infinity)
        : []


    /**
     * Reset Filter
     */
    const resetFilter = () => setQueryParams(q => ({
        ...q,
        page: 1,
        filter: {
            ...q.filter,
            "workingOrganizationPlans.organizationRequirements.statuses": [],
            "workingOrganizationPlans.approved": null,
            reseller_id: [],
            "workingOrganizationPlans.template_plan_id": null
        }
    }))

    const getPlanTemplates = () => {

        if(!loadingStatus){
            setLoadingStatus(true)

            getWorkingTemplatePlans({ include: ["requirementStatuses"]},(res) => {
                setPlanStatus(res.data)
                setLoadingStatus(false)
            }, () => setLoadingStatus(false))
        }

    }



    useEffect(() => {
        getPlanTemplates()
    }, [])


    const getPlanData = async (workingPlan) => {
        const body = {
            id: workingPlan.organization_id,
            idPlan: workingPlan.id,
            include: [
                "organization",
                "organizationChapters.organizationGoals.organizationRequirements.templateRequirement",
                "templatePlan.templatePrintPresets",
            ],
            append: ["organization_requirements_statuses"],
        };

        const plan = await getOrganizationPlan(body, (res) => {}, () => {});

        return plan?.data ?? null
    }


    /**
     * Download plan
     * @param workingPlan
     * @returns {Promise<void>}
     */
    const downloadPlan = async (workingPlan) => {
        setLoadingPage(true)
        const data = await getPlanData(workingPlan)

        if(data){
            setPlanData(data)
            setTimeout(() => {
                setLoadingPage(false)
                setOpenPrinterModal(true)
            }, 100)
        }
    }

    /**
     * Approve plan
     * @param workingPlan
     * @returns {Promise<void>}
     */
    const approvePlan = async (workingPlan) => {
        setLoadingPage(true)
        const data = await getPlanData(workingPlan)

        if(data){
            setPlanData(data)
            setTimeout(() => {
                setLoadingPage(false)
                setOpenApprovalModal(true)
            }, 100)
        }
    }


    return (
        <>
            <div className={c.main_intro}>
                <div className={c.left_content}>
                    <div className={c.main_fitlers}>

                        {/* Filter Status */}
                        <FilterDropdownCollapse
                            options={planStatus.map(plan =>({
                                id: plan.id,
                                label: plan.name,
                                items: plan.requirement_statuses.map(status => ({
                                    label: status.name,
                                    icon: `icon_16 status ${status.icon}`,
                                    value: status.id
                                }))
                            }))}
                            searchFilter={true}
                            isLoading={loadingStatus}
                            activeSelected={filteredStatus}
                            onApply={(items) => filterByStatus(items)}
                            toggleLabel="Stato linee d'azione"
                        />

                        {/* Plan filter */}
                        <FilterDropdown
                            options={[
                                {label: "Mostra tutti", value: null},
                                ...planStatus.map(plan =>({ value: plan.id, label: plan.name }))
                            ]}
                            isLoading={loadingStatus}
                            activeSelected={queryParams.filter["workingOrganizationPlans.template_plan_id"]}
                            onApply={(item) => changeFilters("workingOrganizationPlans.template_plan_id", item)}
                            toggleLabel="Piano"
                            searchFilter={true}
                            type="radio"
                        />

                        {/* Approvals filter */}
                        <FilterDropdown
                            options={[
                                {label: "Mostra tutti", value: null},
                                {label: "Piani adottati", value: 1},
                                {label: "Piani non adottati", value: 0},
                            ]}
                            activeSelected={queryParams.filter["workingOrganizationPlans.approved"]}
                            onApply={(items) => changeFilters("workingOrganizationPlans.approved",items)}
                            toggleLabel="Adozioni"
                            type="radio"
                        />

                        {!isReseller &&
                            <FilterDropdown
                                options={resellers.map(el => ({label: el.name, value: el.id}))}
                                activeSelected={queryParams.filter.reseller_id}
                                onApply={(items) => changeFilters("reseller_id", items)}
                                toggleLabel="Rivenditori"
                                searchFilter
                                isLoading={resellersLoading}
                            />
                        }

                        {(queryParams?.filter["workingOrganizationPlans.organizationRequirements.statuses"].length > 0 ||
                            queryParams?.filter["workingOrganizationPlans.approved"] != null ||
                            queryParams?.filter["workingOrganizationPlans.template_plan_id"] != null ||
                            queryParams?.filter["reseller_id"].length > 0
                        ) &&
                            <div className={c.main_reset} onClick={resetFilter}>
                                <div className={c.main_icon}>
                                    <span className="icon_16 default cross icon_mask !bg-interactive-100"></span>
                                </div>
                                Resetta
                            </div>
                        }
                    </div>
                </div>
                <div className={c.right_content}>
                    <div className="wrap_input_landing icon">
                        <input type="text" onChange={debouncedSearch} className="landing" placeholder="Ricerca..."/>
                        <span className="icon_16 default search"></span>
                    </div>
                </div>
            </div>

            <div className="relative">

                {organizations.length > 0 ?
                    <div className="fade_in">
                        <Table>
                            <Table.Head>
                                <Table.Column>Logo e nome</Table.Column>
                                {!isReseller && <Table.Column>Rivenditore</Table.Column>}
                                <Table.Column></Table.Column>
                            </Table.Head>
                            <Table.Body>
                                {organizations.map((el, index) => (
                                    <Fragment key={el.id}>
                                        <Table.Row className={"action_hover_list" + (!!el?.working_organization_plans?.length ? " !border-b-0" : "")}>
                                            <Table.Cell width={350}>
                                                <div className="name_logo">
                                                    {el.logo_url ?
                                                        <div className={`main_cover`}>
                                                            <img src={el.logo_url} className="logo"/>
                                                        </div>
                                                        :
                                                        <div className="main_cover">
                                                            <div className="main_icon">
                                                                <span className="icon_16 default entity"></span>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div>
                                                        <div>{el.name}</div>
                                                        <div className={`flex items-center text-[13px] whitespace-nowrap`}>
                                                            <span className={`flex items-center font-medium  ${subscriptionStatus[el?.subscription_status].classText}`}>
                                                                <span  className={`inline-block mr-[4px] h-[6px] w-[6px] rounded-[50%] ${subscriptionStatus[el?.subscription_status].classCircle}`}></span>
                                                                {subscriptionStatus[el?.subscription_status].label}
                                                            </span>
                                                            {!!el.subscription_end_date &&
                                                                <span className={`ml-[4px] opacity-70`}>
                                                                    {el?.subscription_status === 'active' ? 'fino al' : 'il'} {formatDate(el.subscription_end_date, "DD MMM YYYY")}
                                                                </span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Table.Cell>

                                            {!isReseller &&
                                                <Table.Cell width={250}>
                                                    {el?.reseller?.name ? el.reseller.name : "–"}
                                                </Table.Cell>
                                            }

                                            <Table.Cell width={200}>
                                                <div className="spacing_items">
                                                    {!isReseller
                                                        ?
                                                        <Link to={"/admin/entities/entity/" + el.id}
                                                              className="button fill tertiary x_small"
                                                        >
                                                            Dettaglio
                                                        </Link>
                                                        :
                                                        <Link to={"/reseller/" + el.id}
                                                              className="button fill tertiary x_small">Dettaglio
                                                        </Link>
                                                    }
                                                </div>

                                            </Table.Cell>
                                        </Table.Row>
                                        {!!el?.working_organization_plans?.length &&
                                            <Table.Row className={"action_hover_list bg-gradient-to-b !rounded-[unset] from-white to-interactive-10"}>
                                                <Table.Cell colSpan={20} className="!p-[0px_16px_16px] !text-left">
                                                    <Table className="bg-white shadow-[0_0_0_1px] rounded-[6px] shadow-dark-10">
                                                        <Table.Body>
                                                            {el?.working_organization_plans.map(plan => (
                                                                <Table.Row key={plan.id} className="action_hover_list">
                                                                    <Table.Cell className="!pl-5" width={200}>{plan.template_plan.name}</Table.Cell>

                                                                    <Table.Cell width={200}>
                                                                        <span>Approvazioni: {plan?.organization_plan_approvals_count != undefined ? plan?.organization_plan_approvals_count : "0"}</span>
                                                                        {plan?.last_organization_plan_approval &&
                                                                            <div className="subtitle whitespace-nowrap">
                                                                                    Ultima: {formatDate(plan.last_organization_plan_approval.date, "DD MMM YYYY")}
                                                                            </div>
                                                                        }
                                                                    </Table.Cell>


                                                                    <Table.Cell width={200}>
                                                                        {(plan?.completion_percentage < 100 ?
                                                                            <div className="progress_item">
                                                                                <div className="progress_bar">
                                                                                    <div className="progress_value"
                                                                                         style={{width: (plan ? plan.completion_percentage : 0) + "%"}}></div>
                                                                                </div>
                                                                                <div
                                                                                    className="value">{plan ? plan.completion_percentage : 0}%
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <span className="flex items-center gap-1 text-success-100">
                                                                                <span className="icon_16 check default icon_mask !bg-success-100"></span>
                                                                                Preso in carico
                                                                            </span>
                                                                        )}
                                                                    </Table.Cell>

                                                                    <Table.Cell width={350}>
                                                                        <div className="spacing_items !justify-start flex-wrap">
                                                                            {plan.organization_requirements_statuses.map(status =>
                                                                                <div key={status.id} className="main_icon_text">
                                                                                    <span className={"icon_16 status " + status.icon}></span>
                                                                                    {status.count}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </Table.Cell>


                                                                    <Table.Cell width={150}>
                                                                        <div className="spacing_items">
                                                                            <div className="action_list">
                                                                                <Link to={entityAccessLink(plan)}
                                                                                      className="action"
                                                                                      data-tooltip-id="tooltip_small_top"
                                                                                      data-tooltip-offset={10}
                                                                                      data-tooltip-content="Accedi">
                                                                                    <span className="icon_16 default in_entity icon_mask !bg-interactive-100"></span>
                                                                                </Link>

                                                                                <button className="action"
                                                                                        data-tooltip-id="tooltip_small_top"
                                                                                        data-tooltip-offset={10}
                                                                                        data-tooltip-content="Scarica"
                                                                                        onClick={() => downloadPlan(plan)}
                                                                                >
                                                                                    <span
                                                                                        className="icon_16 default download icon_mask !bg-interactive-100"></span>
                                                                                </button>

                                                                                <button className="action"
                                                                                        data-tooltip-id="tooltip_small_top"
                                                                                        data-tooltip-offset={10}
                                                                                        data-tooltip-content="Approva"
                                                                                        onClick={() => approvePlan(plan)}
                                                                                >
                                                                                    <span
                                                                                        className="icon_16 default approved icon_mask !bg-interactive-100"></span>
                                                                                </button>

                                                                                {!!plan?.agenda?.id &&
                                                                                    <Link
                                                                                        to={isReseller ?
                                                                                            `/reseller/agendas/${plan.organization_id}/${plan.agenda.id}`
                                                                                            :
                                                                                            `/admin/agendas/${plan.organization_id}/${plan.agenda.id}`
                                                                                        }
                                                                                        className="action"
                                                                                        data-tooltip-id="tooltip_small_top"
                                                                                        data-tooltip-offset={10}
                                                                                        data-tooltip-content="Agenda">
                                                                                        <span className="icon_16 default note icon_mask !bg-interactive-100"></span>
                                                                                    </Link>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            ))}
                                                        </Table.Body>
                                                    </Table>
                                                </Table.Cell>
                                            </Table.Row>
                                        }
                                    </Fragment>
                                ))}
                            </Table.Body>
                        </Table>

                        <Pagination
                            last={pageData.last}
                            current={pageData.current}
                            total={pageData.total}
                            onChange={updatePage}
                        />
                    </div>
                    :
                    initStatus &&
                    <EmptyBox
                        icon="icon_75 default archive_entity"
                        title="Nessun risultato trovato"
                        description="Qui visualizzerai la lista di tutti gli enti"
                    />
                }
                {queueLoading.length > 0 && <LoadingBox blocked={!initStatus}/>}
            </div>


            {planData &&
                <>
                    <PlanDownloadModal
                        open={openPrinterModal}
                        setOpen={setOpenPrinterModal}
                        template={formatOrganizationPlanDownloadObject(planData.organization_contents)}
                        idOrganization={planData.organization_id}
                        idPlan={planData.id}
                        statuses={planData.organization_requirements_statuses}
                        isAdmin={!isReseller}
                        presets={planData.template_plan.print_templates}
                    />

                    {/* Approval popup */}
                    <CrudApprovalPopup
                        open={openApprovalModal}
                        plan={planData}
                        onSuccess={() => getListOrganizations()}
                        setOpen={(isOpen) => setOpenApprovalModal(isOpen)}
                    />
                </>
            }


            {loadingPage && <LoaderPage title="Caricamento in corso..."/>}
        </>
    );
}

export default TableEntities;
