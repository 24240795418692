import { create } from 'zustand'
import {updatePlanContent} from "../services/plans.service";
import {createActivity} from "../services/activities.service";


const initialState = {
    planDetail: {},
    searchModel: "",
    searchLoading: false,
    searchResults: {
        organization_chapters: [],
        organization_chapter_contents: [],
        organization_goals: [],
        organization_requirements: [],
    },
    isSavingContent: [],
    changesList: [],
    roleSettings: null,
    isPlanApproved: false,
    isLoadingSave: false,
    selectedContent: null,
    chapterList: [],
    isEndSessionOpen: false,
    isConfirmOpen: false,
    isApprovalOpen: false,
    courses: []
}

export const usePlanCompileStore = create((set,get) => ({
    ...initialState,
    updateState: (newState) => set(state => ({ ...newState })),


    /**
     * Edit Content
     * @param {*} editor
     * @returns
     */
    editContent: async (type = 'editorjs', data, successCallback = () => {}) => {
        const isSavingContent = get().isSavingContent
        const selectedContent = get().selectedContent
        const planDetail = get().planDetail

        isSavingContent.push("queue")

        const body = {
            id: selectedContent.content_id,
            idCorp: planDetail.organization_id,
            ...(type === 'editorjs' && {editorjs: data}),
            ...(type === 'adminNote' && {admin_note: data}),
        }

        await updatePlanContent(body)

        set(state => ({ selectedContent: {
                ...selectedContent,
                data: {
                    ...selectedContent.data,
                    ...type === "editorjs" && {editorjs: data},
                    ...type !== "editorjs" && {admin_note: data}
                }
            }
        }))

        get().storeContentsChanges(selectedContent.data, type === "editorjs" ? "editorjs" : "adminNote")

        isSavingContent.pop()

        set(state => ({ isSavingContent }))

        successCallback()
    },

    /**
     * Store Contents Changes
     */
    storeContentsChanges: (content, type = 'editorjs') => {
        const chapterList = get().chapterList
        const changesList = get().changesList

        const chapter = chapterList.find(c => c.content_id === content.organization_chapter_id)

        const findChapter = changesList.find(c => c.id === chapter.content_id)

        const contentsChanges = {
            id: content.id,
            name: content.template_chapter_content.name
        }

        // New chapter
        if (!findChapter) {

            changesList.push({
                id: chapter.content_id,
                name: chapter.data.template_chapter.name,
                contents: [{...contentsChanges, ...(type === 'adminNote' && {admin_note: content.admin_note})}]
            })

        }else if (!findChapter?.contents) {

            findChapter.contents = [{
                ...contentsChanges,
                ...(type === 'adminNote' && {
                    admin_note: content.admin_note
                })
            }]

        }else{

            const findContent = findChapter.contents.find(c => c.id === content.id)

            const findContentIndex = findChapter.contents.findIndex(c => c.id === content.id)

            // New Content
            if (!findContent) {
                findChapter.contents.push(contentsChanges)
            }else{
                findChapter.contents[findContentIndex] = {
                    ...contentsChanges,
                    ...((type === 'adminNote' || findContent.admin_note !== undefined) && {
                        admin_note: content.admin_note
                    })
                }
            }
        }

        set(state => ({ changesList: [...changesList]}))
    },


    /**
     * Edit Content
     * @param {*} editor
     * @returns
     */
    updateContentStatus: async (status) => {
        const isSavingContent = get().isSavingContent
        const selectedContent = get().selectedContent
        const chapterList = get().chapterList
        const planDetail = get().planDetail

        isSavingContent.push("queue")

        await updatePlanContent({id: selectedContent.content_id, idCorp: planDetail.organization_id, status: status})

        const findChapt = chapterList.find(c => c.content_id === selectedContent.data.organization_chapter_id)

        const findContent = findChapt.data.organization_contents.find(c => c.content_id === selectedContent.content_id && c.content_type === selectedContent.content_type)

        findContent.data.status = status

        selectedContent.data.status = status

        get().storeContentsChanges(selectedContent.data)

        isSavingContent.pop()

        set(state => ({
            selectedContent: { ...selectedContent},
            chapterList: [...chapterList],
            isSavingContent: isSavingContent
        }))
    },

    /**
     * Store goals Changes
     */
    storeRequirementChanges: (item, goal, changes) => {
        const changesList = get().changesList
        const chapterList = get().chapterList

        const chapter = chapterList.find(c => c.content_id === goal.organization_chapter_id)

        const findChapter = changesList.find(c => c.id === chapter.content_id)

        const newReq = {
            id: item.id,
            name: item.template_requirement.name,
            descriptionTemplate: item.template_requirement.description,
            [changes.key]: changes.value,
        }

        // New chapter
        if (!findChapter) {
            changesList.push({
                id: chapter.content_id,
                name: chapter.data.template_chapter.name,
                goals: [{
                    id: goal.id,
                    name: goal.template_goal.name,
                    reqs: [{...newReq}]
                }]
            })

        }else if (!findChapter.goals) {

            findChapter.goals = [{
                id: goal.id,
                name: goal.template_goal.name,
                reqs: [{...newReq}]
            }]

        }else{

            const findGoal = findChapter.goals.find(c => c.id === goal.id)

            // New Goal
            if (!findGoal) {

                findChapter.goals.push({
                    id: goal.id,
                    name: goal.template_goal.name,
                    reqs: [{...newReq}]
                })

                /*return set(state => ({ changesList: [...changesList]}))*/
            }else{

                const findReq = findGoal.reqs.find(r => r.id === item.id)

                // New Req
                if (!findReq) {
                    findGoal.reqs.push(newReq)
                    /*return set(state => ({ changesList: [...changesList]}))*/
                }else{
                    findReq[changes.key] = changes.value
                }
            }
        }

        set(state => ({ changesList: [...changesList]}))
    },


    /**
     * Redirect Plan
     */
    redirectPlan: () => {

        get().saveActivities()

        set(state => ({ changesList: [] }))

        /*setTimeout(() => {
            navigate(`/admin/entities/entity/${planDetail.organization_id}`, {replace: true})
        }, 0)*/
    },

    /**
     * Reset Data
     */
    reset: () => set(JSON.parse(JSON.stringify(initialState))),
}))