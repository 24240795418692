import c from "./BoxCurrentPlan.module.scss"
import CounterStatus from "./CounterStatus";
import { Link } from "react-router-dom";
import EmptyBox from "../emptystate/EmptyBox";

function BoxCurrentPlan({title, workingPlan, linkDetail, linkConfiguration, role = "admin"}) {

    return (
        <>
            {workingPlan?.organization_requirements_statuses ?
                <div className={c.main_current_plan}>

                    <div className={c.main_heading}>
                        <div className={c.title}>{title}</div>

                        <div className={c.main_cta}>
                            {role === "admin" ?
                                <Link to={linkConfiguration} className="button fill x_small interactive">
                                    <span className="icon_16 default in_entity icon_mask !bg-white"></span>
                                    Accedi come Ente
                                </Link>
                                :
                                <Link to={linkConfiguration} className="button fill x_small secondary">
                                    <span className="icon_16 default wrench icon_mask !bg-primary-100 -ml-2"></span>
                                    Gestisci
                                </Link>
                            }
                            <Link to={linkDetail} className="button fill x_small tertiary">
                                Dettaglio ›
                            </Link>
                        </div>
                    </div>

                    <div className={c.list_status}>
                        <div className={c.title}>Stato linee d'azione</div>
                        <div className={c.wrap_list}>
                            {workingPlan.organization_requirements_statuses.map(status =>
                                <CounterStatus
                                    key={status.id}
                                    value={status.count}
                                    icon={"icon_16 status " + status.icon}
                                    label={status.name}
                                />
                            )}
                        </div>
                    </div>


                    <div className={c.list_status}>
                        <div className={c.title}>Stato documenti</div>
                        <div className={c.wrap_list}>
                            <CounterStatus
                                value={workingPlan.organization_chapter_contents_statuses.find(el => el.status === "uncompleted").count}
                                icon={"icon_16 status uncompleted"}
                                label="Da gestire"
                            />

                            <CounterStatus
                                value={workingPlan.organization_chapter_contents_statuses.find(el => el.status === "completed").count}
                                icon={"icon_16 status completed"}
                                label="Completati"
                            />
                        </div>
                    </div>

                    <div className={c.main_progress_info}>

                        <div className={c.list_info}>

                            <div className={c.info_plan}>
                                <span className="icon_16 default folder"></span>
                                {workingPlan?.organization_chapters_count}
                            </div>

                            <div className={c.info_plan}>
                                <span className="icon_16 default text_box"></span>
                                {workingPlan?.organization_chapter_contents_count}
                            </div>

                            <div className={c.info_plan}>
                                <span className="icon_16 default layers"></span>
                                {workingPlan.organization_goals_count}
                            </div>

                            <div className={c.info_plan}>
                                <span className="icon_16 default megaphone icon_mask !bg-secondary-100"></span>
                                {workingPlan.template_requirements_statuses.find(el => el.status == "new").count}
                            </div>

                            <div className={c.info_plan}>
                                <span className="icon_16 default update icon_mask !bg-secondary-100"></span>
                                {workingPlan.template_requirements_statuses.find(el => el.status == "modified").count}
                            </div>

                        </div>

                        <div className={c.main_progress}>
                            <span className={c.label}>Avanzamento</span>
                            <div className={c.progress_container}>
                                <div style={{ width: `${workingPlan.completion_percentage}%` }} className={c.progress}></div>
                            </div>
                            <span className={c.value}>{workingPlan.completion_percentage}%</span>
                        </div>
                    </div>
                </div>
                :
                <EmptyBox
                    icon="icon_75 default archive"
                    title="Nessun piano in lavorazione"
                    description="Qui visualizzerai il tuo piano in lavorazione"
                />
            }
        </>
    );
}

export default BoxCurrentPlan;
