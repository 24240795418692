import { useState, useEffect, createContext } from "react";
import { apiClient, apiClientPlatform } from "../services/config";
import { getOrganization, userLogin } from "../services/auth.service";
import { getLocalSession } from '../helper/common.helper';

const GlobalContext = createContext({
	user: "",
    setUser: function(){},
	organization: "",
    setOrganization: function(){},
	token: "",
    setToken: function(){},
});

export function GlobalContextProvider(props) {

	// Global attributes
	const [user, setUser] = useState("");

	const [organization, setOrganization] = useState("");

	const [token, setToken] = useState("");

    useEffect(() => {

        const session = getLocalSession()

        if(session?.token){
            setToken(session.token)
        }
    }, [])


    useEffect(() => {
        if(token){

            const session = getLocalSession()

            userLogin({ id: session.id }, function(response){
                setUser(response.data)   
            })

            if(session.user.role === "organization"){
                getOrganization({id: session.user.organization_id}, (res) => {
                    setOrganization(res.data)
                })
            }
        }else{
            /* delete apiClient.defaults.headers.common['Authorization']; */
        }
    }, [token])

	const context = {
		user,
        setUser,
		organization,
        setOrganization,
        token,
        setToken
	};

	return (
		<GlobalContext.Provider value={context}>
			{props.children}
		</GlobalContext.Provider>
	);
}

export default GlobalContext;
