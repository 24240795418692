import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import c from "../plan-template/GoalsContent.module.scss"
import { useState, useRef, useEffect } from "react";
import ActionLineCompile from "../plan-template/ActionLineCompile";
import StatusIcon from '../utils/StatusIcon';
import { Tooltip } from "react-tooltip";
import {usePlanCompileStore} from "../../store/plan-compile.store";



function GoalsContent({ isEditable }) {

    const [
        selectedContent,
        searchResults,
        searchModel,
        planDetail
    ] = usePlanCompileStore(state => [
        state.selectedContent,
        state.searchResults,
        state.searchModel,
        state.planDetail,
    ])
    
    const [selectedItem, setSelectedItem] = useState()

    const enableScroll = useRef(true)

    const actionItemsRefs = useRef([])

    const listContainerRef = useRef()


    /**
     * Open Content
     * @param {*} item 
     */
    const openActionLine = (item) => {
        setSelectedItem(item)
        scrollToItem(item.id)
    }




    /**
     * Scroll to Item
     */
    function scrollToItem(id){

        const hList = listContainerRef.current.offsetTop;

        const scrollTo = document.querySelector(`#action-${id}`).offsetTop

        enableScroll.current = false

        window.scroll({ top: scrollTo - hList, left: 0 })

        setTimeout(() => enableScroll.current = true, 0)
    }



    
    /**
     * On scroll handler
     */
    function onScrollItem (){
        
        if(enableScroll.current && listContainerRef.current){

            const hList = listContainerRef.current.offsetTop;

            const el = actionItemsRefs.current.reduce((a,el) => {

                if(el){

                    const elTop = Math.abs(el.getBoundingClientRect().top - hList)
    
                    return [...a, { id: el.dataset.id, value: elTop }]
                }
                
                return [...a]

            }, [])
            
            const elId = el.reduce((a,b) => a.value < b.value ? a : b).id;

            setSelectedItem(selectedContent.data.organization_requirements.find(el => el.id == elId))
        }
    }


    /**
     * Update scroll item by position
     */
    useEffect(() => {
        window.addEventListener("scroll", onScrollItem)

        return () => {
            window.removeEventListener("scroll", onScrollItem)
        };
    }, [selectedContent.data.organization_requirements])


    const findStatus = (id) => planDetail.organization_requirements_statuses.find(s => s.id === id)


    const showRequirement = (item) => {
        return searchResults.organization_requirements.includes(item.id)
            || !searchModel
            || (selectedContent.data.organization_requirements.every(r => !searchResults.organization_requirements.includes(r.id))
                && (searchResults.organization_goals.includes(selectedContent.content_id) ||
                    searchResults.organization_chapters.includes(selectedContent.data.organization_chapter_id)
                )
            )
    }


    return (
        <>
            <div className={c.main_goals_container}>

                {selectedContent.data.organization_requirements.length > 0 &&
                
                    <div className={c.main_side_list}>

                        {/* Drag Contaienr */}
                        <div className={c.main_list_actions}>
                            <OverlayScrollbarsComponent defer options={{ scrollbars: { autoHide: "scroll" }}} className={c.wrap_list_actions}>

                                {selectedContent.data.organization_requirements.map((item, index) => (
                                    showRequirement(item) &&
                                    // Item action
                                    <div key={item.id} className={`${c.item_action} ${c.not_draggable} ${selectedItem?.id == item.id ? c.active : ''}`} onClick={() => openActionLine(item)} data-tooltip-id="description_plan_small_right" data-tooltip-offset={10}  data-tooltip-content={item.template_requirement.description}>
                                        <div className={c.left_content}>

                                            <div className={`${c.main_icon} ${c.type}`}>
                                                <span className={"icon_16 default " + (item.errorValidationStatus ? 'layer_fill icon_mask !bg-red-100' : 'layer')}></span>
                                            </div>

                                            {item?.template_requirement?.status !== "default" &&
                                                <div className={`${c.main_icon} ${c.type}`}>
                                                    <span className={"icon_16 default icon_mask " + (item?.template_requirement?.status === "modified" ? "update " : "megaphone ") + (item.errorValidationStatus ? '!bg-red-100' : '!bg-interactive-100')}></span>
                                                </div>
                                            }

                                            <span className={c.name}>{item.template_requirement.name}</span>

                                            <StatusIcon
                                                tooltip={true}
                                                icon={findStatus(item.requirement_status_id).icon}
                                                label={findStatus(item.requirement_status_id).name}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </OverlayScrollbarsComponent>
                        </div>
                    </div>
                }


                {/* List Form */}
                <div className={c.main_list_form} ref={listContainerRef}>

                    {selectedContent.data.organization_requirements.map((item, index) => (
                        showRequirement(item) &&
                            <div ref={(ref) => actionItemsRefs.current[index] = ref} data-id={item.id} key={item.id} id={"action-" + item.id} className={c.item_form}>
                                <div className={c.main_form}>
                                    <ActionLineCompile
                                        item={item}
                                        goal={selectedContent}
                                        position={index}
                                    />
                                </div>
                            </div>
                    ))}
                </div>
            </div>

            <Tooltip id="description_plan_small_right" place="right" classNameArrow="tooltipster_arrow right" className="tooltipster small" closeOnScroll={true} positionStrategy="fixed" />
        </>
    );
}

export default GoalsContent;