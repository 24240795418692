import "./BoxContentPlan.scss"
import Box from "./Box";

function ListContent({children}) {
    return (
        <div className="main_comp_box_content_plan">
            {children}
        </div>
    );
}

ListContent.Box = Box

export default ListContent;