import { apiClient } from "./config"






/**
 * Get template plan content
 * @param {title,types,page,per_page,} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getTemplatePlanContent = async (params, successCallback = false, errorCallback = false) => {

    const { id } = params

    try{
        const response = await apiClient.get(`/admin/templates/contents/${id}`, {params: params})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}









/**
 * Create new template plans
 * @param {name, description, year, active} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createTemplatePlan = async (body, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClient.post(`/admin/templates/plans`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}








/**
 * Edit template plans
 * @param {id, name, description, year, active} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const editTemplatePlan = async (body, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClient.patch(`/admin/templates/plans/${body.id}`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}








/**
 * Delete template plans
 * @param {id} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteTemplatePlan = async (id, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClient.delete(`/admin/templates/plans/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}









/**
 * Duplicate template plans
 * @param {id, with_organizations} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const duplicateTemplatePlan = async (params, successCallback = false, errorCallback = false) => {

    const { id, ...query } = params

    try{
        const response = await apiClient({
            method: "copy",
            url: `/admin/templates/plans/${id}`,
            params : { ...query }
        })
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}











/**
 * Get list template plans
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getTemplatePlans = async (params, successCallback = false, errorCallback = false) => {
    try{
        const response = await apiClient.get(`/admin/templates/plans`, {params: params})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}




/**
 * Search in Template Plan
 * @param { q }
 * @param successCallback
 * @param errorCallback
 * @returns {Promise<*>}
 */
export const searchInTemplatePlan = async ({ params, options }, successCallback = false, errorCallback = false) => {

    const {id, ...body} = params

    try{
        const response = await apiClient.get(`/admin/templates/plans/${id}/search`, {params: body, ...options})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}






/**
 * Get list template plans
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const getTemplatePlan = async (params, successCallback = false, errorCallback = false) => {

    const {id, ...moreParams} = params

    try{
        const response = await apiClient.get(`/admin/templates/plans/${id}`, {params: moreParams})
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}








/**
 * Crate new chapter template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createTemplateChapter = async (params, successCallback = false, errorCallback = false) => {

    const { id_plan, ...body } = params 

    try{
        const response = await apiClient.post(`/admin/templates/plans/${id_plan}/chapters`, body )
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}








/**
 * Crate new chapter template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const updateTemplateChapter = async (params, successCallback = false, errorCallback = false) => {

    const { id, ...body } = params 

    try{
        const response = await apiClient.patch(`/admin/templates/chapters/${id}`, body )
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}







/**
 * Delete chapter template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteTemplateChapter = async (id, successCallback = false, errorCallback = false) => {

    try{
        const response = await apiClient.delete(`/admin/templates/chapters/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}









/**
 * Sort chapter template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const sortTemplateChapter = async (params, successCallback = false, errorCallback = false) => {

    const {id, ...body} = params

    try{
        const response = await apiClient.post(`/admin/templates/plans/${id}/sort-chapters`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}









/**
 * Sort content template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const sortTemplateContent = async (params, successCallback = false, errorCallback = false) => {

    const {id_chapter, ...body} = params

    try{
        const response = await apiClient.post(`/admin/templates/chapters/${id_chapter}/sort-contents`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}


/**
 * Sort contents template
 * @param {contents} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const sortTemplateContents = async (params, successCallback = false, errorCallback = false) => {

    const {id_template, ...body} = params

    try{
        const response = await apiClient.post(`/admin/templates/plans/${id_template}/sort`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}







/**
 * Sort goals template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const sortTemplateGoals = async (params, successCallback = false, errorCallback = false) => {

    const {id_chapter, ...body} = params

    try{
        const response = await apiClient.post(`/admin/templates/chapters/${id_chapter}/sort-goals`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}








/**
 * Sort requirements template
 * @param {id, requirementsIds[]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const sortTemplateRequirements = async (params, successCallback = false, errorCallback = false) => {

    const {id, ...body} = params

    try{
        const response = await apiClient.post(`/admin/templates/goals/${id}/sort-requirements`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}










/**
 * Crate new content template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createTemplateContent = async (params, successCallback = false, errorCallback = false) => {

    const { id_chapter , ...body } = params 

    try{
        const response = await apiClient.post(`/admin/templates/chapters/${id_chapter}/contents`, body )
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}



/**
 * Crate new course template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const createTemplateCourse = async (params, successCallback = false, errorCallback = false) => {

    const { id_chapter , ...body } = params

    try{
        const response = await apiClient.post(`/admin/templates/chapters/${id_chapter}/courses`, body )
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}








/**
 * Crate new content template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const updateTemplateContent = async (params, successCallback = false, errorCallback = false) => {

    const { id , ...body } = params 

    try{
        const response = await apiClient.patch(`/admin/templates/contents/${id}`, body )
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}


/**
 * Update course template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const updateTemplateCourse = async (params, successCallback = false, errorCallback = false) => {

    const { id , ...body } = params

    try{
        const response = await apiClient.patch(`/admin/templates/courses/${id}`, body )
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}







/**
 * Delete content template
 * @param {id} id
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteTemplateContent = async (id, successCallback = false, errorCallback = false) => {

    try{
        const response = await apiClient.delete(`/admin/templates/contents/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}



/**
 * Delete course template
 * @param {id} id
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const deleteTemplateCourse = async (id, successCallback = false, errorCallback = false) => {

    try{
        const response = await apiClient.delete(`/admin/templates/courses/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}













/**
 * Delete goal template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteTemplateGoal = async (id, successCallback = false, errorCallback = false) => {

    try{
        const response = await apiClient.delete(`/admin/templates/goals/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * Crate new content template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createTemplateGoal = async (params, successCallback = false, errorCallback = false) => {

    const { id_chapter , ...body } = params 

    try{
        const response = await apiClient.post(`/admin/templates/chapters/${id_chapter}/goals`, body )
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * Crate new content template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const updateTemplateGoal = async (params, successCallback = false, errorCallback = false) => {

    const { id , ...body } = params 

    try{
        const response = await apiClient.patch(`/admin/templates/goals/${id}`, body )
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}






/**
 * Delete requirement template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const deleteTemplateRequirement = async (id, successCallback = false, errorCallback = false) => {

    try{
        const response = await apiClient.delete(`/admin/templates/requirements/${id}`)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * Crate new requirement template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const createTemplateRequirement = async (params, successCallback = false, errorCallback = false) => {

    const { id_goal , ...body } = params 

    try{
        const response = await apiClient.post(`/admin/templates/goals/${id_goal}/requirements`, body )
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}





/**
 * Crate new content template
 * @param {include,per_page,filter[search]} params
 * @param {function} successCallback 
 * @param {function} errorCallback 
 * @returns 
 */
export const updateTemplateRequirement = async (params, successCallback = false, errorCallback = false) => {

    const { id , ...body } = params 

    try{
        const response = await apiClient.patch(`/admin/templates/requirements/${id}`, body )
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback) 
            errorCallback(error.response)
        return error
    }
}






/**
 * create export plan template
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const exportTemplateCreate = async (params, successCallback = false, errorCallback = false) => {

    const { idTemplatePlan, ...body} = params

    try{
        const response = await apiClient.post(`/admin/templates/plans/${idTemplatePlan}/exports`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}




/**
 * get export plan template
 * @param {} params
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const exportTemplateShow = async (params, successCallback = false, errorCallback = false) => {

    const { idTemplatePlan, id, ...body} = params

    try{
        const response = await apiClient.get(`/admin/templates/plans/${idTemplatePlan}/exports/${id}`, { params: body })
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}








/**
 * update field plan template
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns
 */
export const updateFieldTemplatePlan = async (params, successCallback = false, errorCallback = false) => {

    const { idTemplatePlan, ...body} = params

    try{
        const response = await apiClient.put(`/admin/templates/plans/${idTemplatePlan}/requirement-fields-settings`, body)
        if(successCallback)
            successCallback(response.data)
        return response.data;
    }catch(error){
        if (errorCallback)
            errorCallback(error.response)
        return error
    }
}

